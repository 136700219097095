<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent
          title="Process Hazards Analysis: The Keystone of Process Safety"
        />
        <Paragraph
          ><p>
            The process hazards analysis (PHA) is an effective method to
            identify and eliminate potential hazards that can occur within a
            process. Recently I had the opportunity to work on a PHA with some
            amazing team members consisting of engineers, operators, safety
            specialists, and management. The effectiveness of the PHA depends
            upon some key factors that occur during the process. Here are three
            very important elements that foster an effective PHA:
          </p>
          <ol>
            <li><strong>Communication</strong></li>
          </ol>
          <p>
            When all members of the PHA share their insights and opinions while
            the team leader facilitates this process, then the team as a whole
            can better identify what hazards truly exist in the process. If only
            a few members are the ones contributing to the conversation, then
            the PHA will not be as comprehensive. Communication and
            collaboration within the PHA team are the only way to ensure that
            the PHA is comprehensive for the entire process.
          </p>
          <ol start="2">
            <li><strong>Diversity</strong></li>
          </ol>
          <p>
            The PHA team should consist of members of varying backgrounds and
            work positions. I cannot emphasize enough through writing how
            effective it is to have an operator in the PHA group sharing real
            scenarios that they have seen over the years they have worked. These
            experiences give real insight into what hazards exist, and what
            possible solutions will work. Ensuring that a variety of personnel
            are involved in the PHA help to gain a fuller perspective of the
            covered process. Some specific roles are required by the PSM
            standard too!
          </p>
          <ol start="3">
            <li><strong>Follow-through</strong></li>
          </ol>
          <p>
            The PHA process identifies specific hazards and mitigation actions
            to remove hazards that are identified through the PHA. Much like a
            golf swing, if there is no follow-through then there is no real
            progress. Ensuring that your team follows up with recommendations
            made during the PHA will provide a safer process at your facility.
          </p>
          <p>
            An effective PHA has a major advantage over a PHA performed soley
            for OSHA compliance. An effective PHA is the only way to provide the
            safest environment for your personnel and operations.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <img src="@/assets/Resources/Blog/Arch-Smooth-min.png" />
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Keystone of Process Safety",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "The most important elements that foster and effective PHA are communication with all team members, having team members from varying backgrounds and work positions, and following through with actions to remove and mitigate hazards."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
